import { useRoute, useRouter } from "vue-router";

export default function () {
  const router = useRouter();
  const route = useRoute();

  const navigateTo = (routeName: string) => {
    router.push({
      name: routeName,
      params: { ...route.params },
      query: { ...route.query },
    });
  };

  return {
    navigateTo,
    error: (message: string) => {
      console.log(message);

      router.push({
        name: "outcome",
        params: {
          error: message,
          ...route.params,
        },
        query: { ...route.query },
      });
    },
    cancel: () => {
      navigateTo("outcome");
    },
  };
}
