
import ApplicationStore from "@/stores/applicationStore";
import { defineComponent, inject, onMounted, reactive, ref } from "vue";
import Spinner from "../components/Spinner.vue";

export default defineComponent({
  components: {
    Spinner,
  },
  props: {
    error: String,
  },
  setup(props) {
    const applicationStore = inject("applicationStore") as ApplicationStore;

    const redirect = () => {
      setTimeout(() => {
        window.location.href = applicationStore.authCallbackURI;
      }, 5000);
    };

    let authorised = ref(false);
    let processing = ref(applicationStore.authorisationPromise !== undefined);
    let errorMessage = ref(props.error);

    onMounted(() => {
      if (applicationStore.authorisationPromise) {
        applicationStore.authorisationPromise
          .then((authOutcome: boolean) => {
            authorised.value = authOutcome;
            processing.value = false;
            redirect();
          })
          .catch((e) => {
            errorMessage.value = e;
            processing.value = false;
            redirect();
          });
      } else {
        processing.value = false;
        redirect();
      }
    });

    return {
      dataRecipient: applicationStore.dataRecipient,
      applicationStore,
      authorised,
      processing,
      errorMessage,
    };
  },
});
