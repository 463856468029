
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    showHeading: {
      type: Boolean,
      default: true,
    },
  },
});
