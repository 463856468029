
import { inject, defineComponent, onMounted, ref } from "vue";
import AuthenticationService from "@/services/authenticationService";
import ClientService from "@/services/clientService";
import { useRoute } from "vue-router";
import InitOtpResponse from "@/domain/initOtpResponse";
import ApplicationStore from "@/stores/applicationStore";
import Footer from "@/components/Footer.vue";
import LoginResponse from "@/domain/loginResponse";
import ConsentService from "@/services/consentService";
import useNavigation from "@/helpers/useNavigation";
import AccountService from "@/services/accountService";

export default defineComponent({
  components: { Footer },
  props: {
    consentId: { type: String, required: true },
  },
  data() {
    return {
      timer: 0,
      username: "",
      otp: "",
      step: "login",
      loginResponse: {},
      timeRemaining: 0,
      PolicyURL: "",
      DH: {
        name: "SocietyOne",
        acn: "609 495 409",
      },
      OTP: {
        expiry: new Date(new Date().getTime() + 10 * 60000),
        phone: "**** *** 746",
      },
      error: "",
      info: "",
    };
  },
  methods: {
    showInfo(text) {
      this.info = text;
      setTimeout(() => (this.info = ""), 5000);
    },
    validateEmail(email: string): boolean {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    cancel() {
      this.navigationHandler.cancel();
    },
    async initOTP() {
      if (!this.validateEmail(this.username)) {
        this.error = "Please enter a valid email";
        return;
      } else {
        this.error = "";
      }

      let initResponse: InitOtpResponse;

      try {
        initResponse = await this.authenticationService.initOTP(
          this.username,
          this.$props.consentId
        );
      } catch (e) {
        this.navigationHandler.error("Error initialising OTP");
        return;
      }

      this.showInfo("Code has been sent");
      this.$data.loginResponse = initResponse;
      this.$data.step = "OTP";
    },
    async redirect() {
      if (!this.$data.otp) {
        this.error = "Please enter the one time password";
        return;
      } else {
        this.error = "";
      }

      let loginResponse: LoginResponse;

      // Login using the OTP
      try {
        loginResponse = await this.authenticationService.loginOTP(
          this.$data.username,
          (this.$data.loginResponse as InitOtpResponse).authId,
          this.$props.consentId,
          this.$data.otp
        );

        this.applicationStore.updateAccessToken(loginResponse.accessToken);
      } catch (e: any) {
        if (e.response.data.message === "Invalid authorisation") {
          this.error = "Incorrect one time password";
        } else {
          this.navigationHandler.error("Error logging in using OTP");
        }

        return;
      }

      // Retrieve the subscriptions so that we can get the party key
      try {
        const accountListResponse = await this.accountService.listAccounts(
          this.applicationStore.accessToken
        );

        const subscriptions = accountListResponse.subscriptions;

        if (subscriptions.length <= 0)
          throw new Error(
            "There are no subscriptions associated with this user"
          );

        this.applicationStore.updatePartyKey(subscriptions[0].partyKey);
        this.applicationStore.updateAllAccounts(subscriptions);
      } catch (e) {
        console.log(e);
        this.navigationHandler.error("There was a problem retrieving accounts");
        return;
      }

      // Retrieve the consent request
      try {
        const consentRequest = await this.consentService.retriveConsent(
          this.applicationStore.accessToken,
          this.applicationStore.partyKey,
          this.$props.consentId
        );

        this.applicationStore.updateConsentRequest(consentRequest);
      } catch (e: any) {
        this.navigationHandler.error("Error retrieving consent request");
        return;
      }

      // Map the scopes to the appropriate dataClusters
      const dataClusters = this.consentService.mapConsent(
        this.applicationStore.consentRequest?.consentRequested?.scope as string
      );

      this.applicationStore.updateDataClusters(dataClusters);

      // If there is a banking scope then we need to select the accounts that the data is made available for
      // Otherwise go straight to the confirmation screen
      if (
        this.applicationStore.consentRequest?.consentRequested?.scope.includes(
          "bank"
        )
      ) {
        this.navigationHandler.navigateTo("select-accounts");
      } else {
        this.navigationHandler.navigateTo("confirmation");
      }
    },
  },
  setup(props) {
    const route = useRoute();

    const authenticationService = inject(
      "authenticationService"
    ) as AuthenticationService;
    const accountService = inject("accountService") as AccountService;
    const consentService = inject("consentService") as ConsentService;
    const clientService = inject("clientService") as ClientService;
    const applicationStore = inject("applicationStore") as ApplicationStore;
    const navigationHandler = useNavigation();

    applicationStore.updateAuthCallbackURI(
      route.query.auth_callback_uri as string
    );

    onMounted(async () => {
      try {
        const recipient = await clientService.retriveClient(
          route.query.client_id as string
        );
        applicationStore.updateDataRecipient(recipient);
      } catch (e) {
        navigationHandler.error("Error retrieving client information");
      }
    });

    return {
      authenticationService,
      accountService,
      navigationHandler,
      consentService,
      applicationStore: ref(applicationStore),
      CONTACT: process.env.VUE_APP_CONTACT,
      CDR_POLICY_LINK: process.env.VUE_APP_CDR_POLICY_LINK,
    };
  },
});
