<template>
  <span style="width: 20px; vertical-align: middle"
    ><i class="Chevron"></i
  ></span>
</template>

<style scoped>
.Chevron {
  position: relative;
  display: block;
  height: 10px; /*height should be double border*/
}
.Chevron:before,
.Chevron:after {
  position: absolute;
  display: block;
  content: "";
  border: 8px solid transparent; /*adjust size*/
  margin-top: 6px;
}
/*Change four 'top' values below to rotate (top/right/bottom/left)*/
.Chevron:before {
  top: 0;
  border-top-color: #264b65; /*Chevron Color*/
}
.Chevron:after {
  top: -3px; /*adjust thickness*/
  border-top-color: #fff; /*Match background colour*/
}
</style>
