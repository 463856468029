
import { defineComponent, ref } from "vue";
import Chevron from "./Chevron.vue";

export default defineComponent({
  components: {
    Chevron,
  },
  props: {
    collapsible: {
      type: Boolean,
      required: false,
    },
  },
  setup(props) {
    let expanded = ref(true);

    const toggle = (): void => {
      if (!props.collapsible) return;
      expanded.value = !expanded.value;
    };

    return {
      expanded,
      toggle,
    };
  },
});
