import * as VueRouter from "vue-router";

import Auth from "./views/Auth.vue";
import Confirmation from "./views/Confirmation.vue";
import Outcome from "./views/Outcome.vue";
import SelectAccounts from "./views/SelectAccounts.vue";

const routes = [
  { path: "/login/:consentId", component: Auth, props: true },
  {
    path: "/consumer-auth/:consentId/accounts",
    component: SelectAccounts,
    name: "select-accounts",
    props: true,
  },
  {
    path: "/consumer-auth/:consentId/confirmation",
    component: Confirmation,
    name: "confirmation",
    props: true,
  },
  {
    path: "/consumer-auth/:consentId/outcome",
    component: Outcome,
    name: "outcome",
    props: true,
  },
  {
    path: "/",
    component: Outcome,
  },
];

const createRouter = () => {
  return VueRouter.createRouter({
    history: VueRouter.createWebHistory(),
    routes,
  });
};

export { routes, createRouter };
