
import { defineComponent } from "@vue/runtime-core";
import { useMeta } from "vue-meta";
import Logo from "./components/Logo.vue";

export default defineComponent({
  name: "Open Banking App",
  components: {
    Logo,
  },
  setup() {
    useMeta({
      title: "Society One Open Banking",
      htmlAttrs: { lang: "en", amp: true },
    });
  },
});
