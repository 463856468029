
import { defineComponent, PropType } from "vue";
import PanelGroup from "./PanelGroup.vue";
import CdrSubscription from "@/domain/cdrSubscription";

export default defineComponent({
  data() {
    return {
      sa: this.selected,
      heading: this.showHeading,
    };
  },
  props: {
    selectable: Boolean,
    accounts: {
      type: Array as PropType<CdrSubscription[]>,
      default: () => [],
    },
    selected: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    showHeading: {
      type: Boolean,
      default: () => true,
    },
  },
  components: {
    PanelGroup,
  },
  methods: {
    toggle(subscriptionKey: string) {
      if (!this.selectable) return;

      if (this.sa.includes(subscriptionKey)) {
        this.sa.splice(this.sa.indexOf(subscriptionKey), 1);
      } else {
        this.sa.push(subscriptionKey);
      }

      this.$emit("selection-changed", this.sa);
    },
    selectAll() {
      this.sa = this.accounts.map((account: any) => {
        return account.subscriptionKey;
      });

      this.$emit("selection-changed", this.sa);
    },
  },
});
