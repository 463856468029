import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9bc99648"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "status"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "checkmark"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 0,
  class: "cross"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      id: "loader",
      class: _normalizeClass([
      { negative: !_ctx.processing && _ctx.outcome === false },
      { positive: !_ctx.processing && _ctx.outcome === true },
    ])
    }, null, 2),
    (!_ctx.processing)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.outcome === true)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (!_ctx.processing)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                      _createElementVNode("div", {
                        class: _normalizeClass([
            { checkmark_stem: _ctx.outcome === true },
            { cross_left: _ctx.outcome === false },
            { processing: _ctx.processing },
            { negative: _ctx.outcome === false },
          ])
                      }, null, 2),
                      _createElementVNode("div", {
                        class: _normalizeClass([
            { checkmark_kick: _ctx.outcome === true },
            { cross_right: _ctx.outcome === false },
            { processing: _ctx.processing },
            { negative: _ctx.outcome === false },
          ])
                      }, null, 2)
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                (!_ctx.processing)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                      _createElementVNode("div", {
                        class: _normalizeClass([
            { checkmark_stem: _ctx.outcome === true },
            { cross_left: _ctx.outcome === false },
            { processing: _ctx.processing },
            { negative: _ctx.outcome === false },
          ])
                      }, null, 2),
                      _createElementVNode("div", {
                        class: _normalizeClass([
            { checkmark_kick: _ctx.outcome === true },
            { cross_right: _ctx.outcome === false },
            { processing: _ctx.processing },
            { negative: _ctx.outcome === false },
          ])
                      }, null, 2)
                    ]))
                  : _createCommentVNode("", true)
              ]))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}