import AccountService from "./services/accountService";
import App from "./App.vue";
import ApplicationStore from "./stores/applicationStore";
import AuthenticationService from "./services/authenticationService";
import ClientService from "./services/clientService";
import ConsentService from "./services/consentService";
import clusterMapping from "@/assets/clusterMapping.json";
import { createApp } from "vue";
import { createMetaManager } from 'vue-meta';
import { createRouter } from "./routes";
import { reactive } from "@vue/runtime-core";

export default function setup(): any {
  const app = createApp(App);
  app.use(createRouter());
  app.use(createMetaManager());

  const applicationStore = reactive(new ApplicationStore());

  if (process.env.VUE_APP_DEBUG === "true") {
    applicationStore.setDebug();
  }

  const authenticationService = new AuthenticationService(
    process.env.VUE_APP_AUTH_SERVER,
    process.env.VUE_APP_API_KEY
  );
  const accountService = new AccountService(
    process.env.VUE_APP_ACCOUNT_SERVER,
    process.env.VUE_APP_API_KEY
  );
  const consentService = new ConsentService(
    process.env.VUE_APP_CONSENT_SERVER,
    process.env.VUE_APP_API_KEY,
    clusterMapping
  );
  const clientService = new ClientService(
    process.env.VUE_APP_CONSENT_SERVER,
    process.env.VUE_APP_API_KEY
  );

  app.provide("authenticationService", authenticationService);
  app.provide("accountService", accountService);
  app.provide("consentService", consentService);
  app.provide("clientService", clientService);
  app.provide("applicationStore", applicationStore);

  return app;
}
