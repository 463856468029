import * as Axios from "axios";

import InitOtpResponse from "@/domain/initOtpResponse";
import LoginResponse from "@/domain/loginResponse";

export default class AuthenticationService {
  constructor(private authServer, private apiKey) {}

  public async initOTP(
    userName: string,
    consentId: string
  ): Promise<InitOtpResponse> {
    const apiConfig: Axios.AxiosRequestConfig = {
      baseURL: this.authServer,
      url: `/v1/otp/initiate`,
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        userName,
        consentId,
      },
    };

    try {
      const response = await Axios.default.request<any>(apiConfig);

      const returnVal = response.data;

      return returnVal as InitOtpResponse;
    } catch (error: any) {
      console.log(error.message);
      throw error;
    }
  }

  public async loginOTP(
    userName: string,
    authId: string,
    consentId: string,
    otp: string
  ): Promise<LoginResponse> {
    const apiConfig: Axios.AxiosRequestConfig = {
      baseURL: this.authServer,
      url: `/v1/otp/login`,
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        otp,
        userName,
        authId,
        consentId,
      },
    };

    try {
      const response = await Axios.default.request<any>(apiConfig);

      return response.data as LoginResponse;
    } catch (error: any) {
      console.log(error.message);
      throw error;
    }
  }
}
