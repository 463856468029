
import { defineComponent } from "vue";
import { ref } from "vue";

export default defineComponent({
  setup(props) {
    const expanded = ref(false);

    const toggle = (): void => {
      expanded.value = !expanded.value;
    };

    return {
      expanded,
      toggle,
    };
  },
});
