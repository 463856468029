import * as Axios from "axios";

import Consent from "@/domain/consent";

export default class ConsentService {
  constructor(private consentServer, private apiKey, private clusterMapping) {}

  public async retriveConsent(
    token: string,
    partyKey: string,
    consentId: string
  ): Promise<Consent> {
    const apiConfig: Axios.AxiosRequestConfig = {
      baseURL: this.consentServer,
      url: `/v1/cdr/consents/${consentId}`,
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    apiConfig.headers["Party-Key"] = partyKey;

    try {
      const response = await Axios.default.request<any>(apiConfig);
      return response.data as Consent;
    } catch (error: any) {
      console.log(error.message);
      throw error;
    }
  }

  public async updateConsent(
    token: string,
    partyKey: string,
    consentId: string,
    authorised: boolean,
    attachedAccounts: string[]
  ): Promise<boolean> {
    const apiConfig: Axios.AxiosRequestConfig = {
      baseURL: this.consentServer,
      url: `/v1/cdr/consents/${consentId}`,
      method: "put",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: {
        authorised,
        attachedAccounts,
      },
    };

    apiConfig.headers["Party-Key"] = partyKey;

    try {
      await Axios.default.request<any>(apiConfig);
      return authorised;
    } catch (error: any) {
      console.log(error.message);
      throw error;
    }
  }

  public mapConsent(scopes: string): any {
    const individualScopes = scopes.split(" ");

    const orderedScopes = individualScopes.sort();

    const scopeMapping = {};
    let index = 0;

    while (index < orderedScopes.length) {
      const scopeParts = orderedScopes[index].split(":");

      if (scopeParts.length < 3) {
        index = index + 1;
        continue;
      }

      const subScopeParts: string[] = scopeParts[1].split(".");

      let mapping: any = undefined;

      // Check to see if it is a basic/detail scope
      if (subScopeParts.length > 1) {
        // Check to see if both basic and detail scopes have been requested
        if (
          subScopeParts[1] === "basic" &&
          index < orderedScopes.length - 1 &&
          orderedScopes[index + 1] ===
            `${scopeParts[0]}:${subScopeParts[0]}.detail:${scopeParts[2]}`
        ) {
          // Both scopes have been requested, so check to see if there is an overriden permission list for the combined group, otherwise merge the basic and detail permissions
          index = index + 1;

          mapping = {
            dataCluster:
              this.clusterMapping[scopeParts[0]][subScopeParts[0]].all
                .dataCluster,
            permissions: this.clusterMapping[scopeParts[0]][subScopeParts[0]]
              .all.permissions
              ? this.clusterMapping[scopeParts[0]][subScopeParts[0]].all
                  .permissions
              : this.clusterMapping[scopeParts[0]][
                  subScopeParts[0]
                ].basic.permissions.concat(
                  this.clusterMapping[scopeParts[0]][subScopeParts[0]].detail
                    .permissions
                ),
          };
        } else {
          // If not return only the requested scope mapping
          mapping =
            this.clusterMapping[scopeParts[0]][subScopeParts[0]][
              subScopeParts[1]
            ];
        }
      } else {
        // Its not a basic/detail scope so return the correct mapping
        if (
          this.clusterMapping[scopeParts[0]] &&
          this.clusterMapping[scopeParts[0]][scopeParts[1]]
        ) {
          mapping = this.clusterMapping[scopeParts[0]][scopeParts[1]];
        }
      }

      index = index + 1;

      if (!mapping) continue;

      scopeMapping[mapping.dataCluster] = mapping.permissions;
    }

    return scopeMapping;
  }
}
