import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e17f09d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "expandable-textbox" }
const _hoisted_2 = { class: "minimized-content" }
const _hoisted_3 = {
  key: 0,
  class: "expanded-content"
}
const _hoisted_4 = { class: "toggle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "minimized", {}, undefined, true)
    ]),
    (_ctx.expanded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "expanded", {}, undefined, true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("a", {
        title: "expand contents to see more",
        class: "add-height toggle-button",
        role: "button",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args))),
        onKeypress: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args))),
        tabindex: "0"
      }, _toDisplayString(_ctx.expanded ? "See less" : "See more"), 33)
    ])
  ]))
}