import * as Axios from "axios";

import CdrRecipient from "@/domain/cdrRecipient";

export default class ClientService {
  constructor(private clientServer: string, private apiKey: string) {}

  public async retriveClient(clientId: string): Promise<CdrRecipient> {
    const apiConfig: Axios.AxiosRequestConfig = {
      baseURL: this.clientServer,
      url: `/v1/cdr/clients/${clientId}`,
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await Axios.default.request<any>(apiConfig);
      return response.data as CdrRecipient;
    } catch (error: any) {
      console.log(error.message);
      throw error;
    }
  }
}
